export const filterData = {
    year: [
        {
            value: 'Now',
            label: "Now",
        },
        {
            value: "nextYear",
            label: "Next year",
        },
    ],
    distances: [
        {
            value: 1,
            label: "Under a mile",

        },
        {
            value: 3,
            label: "0-3 miles",
        },
        {
            value: 5,
            label: "3 miles +",
        }

    ],
    bedroomNumber: [
        {
            value: 0,
            label: 'Shared',
        },
        {
            value: 1,
            label: '1',
        },
        {
            value: 2,
            label: '2',
        },
        {
            value: 3,
            label: '3',
        },
        {
            value: 4,
            label: '4',
        },
        {
            value: 5,
            label: '5',
        },
        {
            value: 6,
            label: '6',
        },
        {
            value: 7,
            label: '7',
        },
        {
            value: 8,
            label: '8',
        },
        {
            value: 9,
            label: '9',
        },
        {
            value: 10,
            label: '10',
        },
    ]
}