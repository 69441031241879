import React from "react";
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';

import { mapStyles } from '../../style/mapStyles';
import { Box } from "@mui/material";

import { style } from "../../style/style"

const containerStyle = {
    width: '100%',
    height: '100%',
};


const options = {
    styles: mapStyles,
};

export default function PropertyMap(props) {


    const centerLat = parseFloat(props.coordinates.latitude);
    const centerLng = parseFloat(props.coordinates.longitude)
    const center = {
        lat: centerLat,
        lng: centerLng,
    };
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || 'AIzaSyBWL4IqEmWjWxqMJqI3eXWhmftcPCGgvts',
    });
    if (loadError) return 'Error loading maps';
    if (!isLoaded) return 'Loading maps';


    return (
        <Box sx={style.propertyMap}>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={13}
                options={options}
            >

                <Marker position={center} />


            </GoogleMap>
        </Box>
    );

}
