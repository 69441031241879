// react
import React, { useState, useEffect } from "react";

//webservice
import { WebServiceClient } from '../services/WebServiceClient'


// mui
import Box from "@mui/material/Box";

import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
//autocomplete and autosuggest
import Autocomplete from '@mui/material/Autocomplete';


import { style } from "../style/style";
import { styled } from "@mui/material/styles";

//icon
import SearchIcon from '@mui/icons-material/Search';

export const CssInputAdornment = styled(InputAdornment)({
  "& .MuiInputAdornment-root": {
    paddingTop: "10px"
  }
});

export const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    paddingTop: "0px",
    borderRadius: "10px",
    backgroundColor: "white",
    "& input": {
      color: "black",
      background: "white",
      "&::placeholder": {
        color: "white",
        textAlign: "center",
        fontWeight: "bold"
      },
    },
    "& fieldset": {
      borderColor: "#34B298",
    },
    "&:hover fieldset": {
      borderColor: "#34B298",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#34B298",
    },
  },
});


export default function SearchBar(props) {
  const [value, setValue] = React.useState(null);
  const [APIData, setAPIData] = useState([]);
  const [index, setUniIndex] = useState();

  //uni index API call


  const handleSearch = () => {
    if (!value) return false
    
    let index = APIData.findIndex(u => u.name === value.name)
    if (index !== -1) {
      setUniIndex(index)
      getData(index)
      props.setUniAllIndex(index); 
    }
  }


  const getData = (index) => {
    console.log('index', index)
    WebServiceClient.GetAll('listing/search?uniIndex=' + index + '&limit=10&skip=1').then(function (response) {
      if (response.data && response.data.data) {
        props.setProperties(response.data.data)
        console.log('apidata', response.data.data)
      }
    })
      .catch(function (error) {
        console.log(error);
      });
  }


  useEffect(() => {
    const fetchData = () => {
      WebServiceClient.FetchAll('swipad/unis')
        .then(function (response) {
          setAPIData(response.data.data)
          console.log(response.data)
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    fetchData()
  }, [])

  useEffect(() => {
    // console.log('value', value)
    handleSearch()
  }, [value])

  return (
    <>
      <Box sx={style.searchBar}>
        <Autocomplete
          sx={{ width: '100%' }}
          id="header-search"
          freeSolo
          disableClearable
          onChange={(e, newValue) => setValue(newValue)}
          value={value}
          blurOnSelect
          options={APIData}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <CssTextField
              {...params}
              label="Enter University"
              InputProps={{
                ...params.InputProps,
                type: "search", endAdornment: (
                  <CssInputAdornment position="end">
                    <SearchIcon />
                  </CssInputAdornment>
                )
              }}
            />
          )}
        />

      </Box>
    </>
  )
}
