import { BorderColor } from "@mui/icons-material";
import { textAlign } from "@mui/system";
import { findByLabelText } from "@testing-library/react";

export const style = {
    app: {
        backgroundColor: "#EBF6F5",
        marginTop: 50,
        margin: 'auto',
        padding: 50,
        justifyContent: "center",
        maxWidth: "95%"
    },
    searchBar: {
        margin: "auto",
        display: "inline-flex",
        width: "650px", 
        maxWidth: '100%'
    },
    filterControl: {
        marginBottom: "0px",
        alignSelf: 'center'
    },
    filterLabel: {
        fontSize: "16px",
        color: "#34B298",
        fontWeight: "bold",
        marginTop: "10px"
    },
    // filterPopup: {
    //     width: "250px",
    //     margin: "10px 70px",
    //     padding: "20px 60px"
    // },
    filterSlider: {
        width: "80%",
        track: {
            color: "#f00"
        }
    },
    filterStack: {
        // justifyContent: "flex-end"
        maxWidth: "1200px",
        margin: "20px 0",
        padding:"10px",
        borderRadius: "10px",
        border: "2px solid #34B298",
        // background: 'white'
    },
    filterBackground:{
        width:"100%",
    },
    filterPrice:{
        margin: "20px 5px"
    },
    filterSelect:{
        margin: "20px 0",
        width: "185px"
    },
    propertyCard: {
        display: "flex",
        maxWidth: "1200px",
        margin: "auto",
        borderRadius: "10px",
        padding: "20px",
        marginTop: "10px",
        marginBottom: "10px",
        border: "2px solid #34B298",
    },
    propertyImage: {
        maxHeight: "250px",
        borderRadius: "10px",
        marginRight: "20px"
    },
    propertyIconContainer: {
        display: "flex",
        // justifyContent: "space-between",
    },
    propertyIconBox: {
        marginTop: "20px",
        marginRight: "10px",
        display: "inline-flex",
        alignItems: "center",
        fontWeight: "bold",
        fontSize: "14px",
        justifyContent: "center",
        border: "2px solid #34B298"
    },
    propertyIconWrapper: {
        marginTop: "20px",
        marginRight: "20px",
        display: "inline-flex",
        alignItems: "center",
        fontWeight: "bold",
        fontSize: "14px",
        justifyContent: "center"
    },
    propertyIcon: {
        height: "20px",
        paddingRight: "5px"
    },
    propertyLinkLabel: {
        fontSize: "14px",
        color: "#34B298",
        fontWeight: "bold"
    },
    propertyStatLabel: {
        fontSize: "14px",
        color: "#34B298",
        fontWeight: "bold"
    },
    propertyPage: {
        backgroundColor: "#FFFFFF",
        border: "2px solid #34B298",
        padding: "20px",
        marginTop: "20px",
        borderRadius: "10px"
    },
    propertyBackBox: {
        width: "200px"
    },
    propertyPageTop: {
        paddingBottom: "20px",
    },
    propertySummaryBox: {
        paddingTop: "20px",
        paddingBottom: "20px",
        textAlign: "left"
    },
    cssTextField: {
        width: "350px",
        fontSize: "20px",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "20px",
    },
    // button
    searchButton: {
        borderRadius: "10px",
        marginLeft: "20px",
        marginBottom: "10px",
        padding: "13px",
        backgroundColor: "#34B298",
        color: "#FFFFFF",
        "&:hover": {
            backgroundColor: "#34B298",
            color: "#FFFFFF",
            opacity: 1
        }
    },
    propertyButton: {
        backgroundColor: "#34B298",
        color: "#FFFFFF",
        width: "100%",
        "&:hover": {
            backgroundColor: "#FFFFFF",
            color: "#34B298",
            border: "1px solid #34B298"
        }
    },
    propertyMap: {
        zIndex: "999999",
        height: "350px",
        width: "100%",
    },
    // typography
    propertyTitle: {
        color: "#34B298",
        fontWeight: "bold",
        fontSize: "24px",
        textAlign: "left",
        paddingBottom: "10px"
    },
    textBold: {
        color: "#34B298",
        fontWeight: "bold",
        fontSize: "20px",
        textAlign: "left",
        paddingBottom: "10px"
    },
    textBoldIcon: {
        color: "#34B298",
        fontWeight: "bold",
        fontSize: "20px",
        textAlign: "left",
        marginRight: "5px"
    },
    bodyText: {
        fontSize: "14px",
        textAlign: "left"
    },
    bodyTextLabel: {
        color: "#34B298",
        fontWeight: "bold",
        fontSize: "16px",
        textAlign: "left",
        marginTop: "10px",
        marginRight: "5px"
    }

}