// react
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment"


//components
import Filter from "./Filter"

// mui
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

//icons
import BedIcon from '../../assets/bed-icon.png'
import LighteningBoltIcon from "./../../assets/lightening-bolt-icon.png";
import Camcorder from '../../assets/camcorder.png'
import Houses from '../../assets/houses.png'
import Calendar from '../../assets/calendar.png'
import Letter from '../../assets/letter-icon.png'

import { style } from "../../style/style";

export default function PropertyListCard(props) {
    const navigate = useNavigate()


    return (
        <>
            {props.uniIndex > 0 && <Box>
                <Filter 
                        uniIndex={props.uniIndex}
                        setProperties={props.setProperties}
                        APIData={props.APIData} 
                />
            </Box>}

            {props.uniIndex > 0 && props.properties.length === 0 && <Typography sx={{...style.bodyText, ...{ textAlign: 'center' }}}>There are no properties that match this search criteria.</Typography>}

            {props.properties.length ? props.properties.map((data) => {
                const AvailableDate = moment(data.availableFrom).format('DD/MM/YYYY');
                const MonthConvert = Math.floor(data.contractLength / 30)
                return (
                    <Card key={data.id}
                        sx={style.propertyCard} >
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <CardMedia
                                    component="img"
                                    image={data.listingMedia.length > 0 ? data.listingMedia[0].record.fileURL : null}
                                    sx={style.propertyImage}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                                <Typography sx={style.propertyTitle}>{data.name}
                                </Typography>
                                <Typography sx={style.bodyText}>{data.briefDescription}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={2} lg={2}>
                                <Typography > £{data.cost_pppw} pppw</Typography>
                                <Button
                                    sx={style.propertyButton}
                                    onClick={() => navigate("/property", { state: { property: data } })}
                                >View</Button>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                            
                            </Grid>
                            <Grid item xs={12} sm={12} md={9} lg={9}>
                                <Box>
                                    <Box sx={style.propertyIconContainer}>
                                        <Box sx={style.propertyIconWrapper} >
                                            <Typography sx={style.textBoldIcon}>
                                                {data.distanceToUniMiles}
                                            </Typography>
                                            <Typography sx={style.propertyStatLabel}>
                                                miles to Uni
                                            </Typography>
                                        </Box>
                                        <Box sx={style.propertyIconWrapper} >
                                            <Box
                                                sx={style.propertyIcon}
                                                component="img"
                                                alt={"icon"}
                                                src={BedIcon}
                                            />
                                            <Typography sx={style.textBoldIcon}>
                                                {data.bedroomCount}
                                            </Typography>
                                            <Typography sx={style.propertyStatLabel}>
                                                Bedrooms
                                            </Typography>
                                        </Box>
                                        <Box sx={style.propertyIconWrapper} >
                                            <Typography sx={style.propertyStatLabel}>
                                                Available from&nbsp;
                                            </Typography>
                                            <Typography sx={style.textBoldIcon}>
                                                {AvailableDate}
                                            </Typography>
                                        </Box>

                                        <Box sx={style.propertyIconWrapper} >
                                            <Typography sx={style.textBoldIcon}>
                                                {MonthConvert}
                                            </Typography>
                                            <Typography sx={style.propertyStatLabel}>
                                                Month Contract
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Card>
                )


            })
                : null
            }

            {/* <div>
        <button>List view</button>
        <button>Grid view</button>
        <button>Map </button>
      </div>
      <div>
        <label for="Sort by Options">Sort by Options:</label>
        <select name="sort" id="sort">
        </select>
      </div> */}

        </>
    )
}
