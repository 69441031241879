// react
import React, { useState, useEffect } from "react";

//webservice
import { WebServiceClient } from '../../services/WebServiceClient'
//data
import { filterData } from "../.././resources/filterSelectData"

// mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import { style } from "../../style/style";
import Slider from '@mui/material/Slider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Typography from "@mui/material/Typography";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
//icon
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';

//autocomplete and autosuggest
import TextField from '@mui/material/TextField';

import Autocomplete from '@mui/material/Autocomplete';
import { margin } from "@mui/system";

import { styled } from "@mui/material/styles";

export default function Filter(props) {
    const [minDistance, setMinDistance] = useState(0)
    const [uniDistance, setUniDistance] = useState()
    const [rentValueMin, setRentValueMin] = useState();
    const [rentValueMax, setRentValueMax] = useState();
    const [bedroomValueMin, setBedroomValueMin] = useState(0);
    const [bedroomValueMax, setBedroomValueMax] = useState();
    const [bedroomValueLabel, setBedroomValueLabel ]= useState()
    const [orgID, setOrgID] = useState('')
    const [orgList, setOrgList] = useState()
    const [propertyDistanceMax, setPropertyDistanceMax] = useState();
    const [propertyDistanceMin] = useState(0);
    const [rentalYear, setRentalYear] = useState(2022)



    //API call after filter submit
    const handleFilter = () => {

        var url = 'listing/search?uniIndex=' + props.uniIndex
        if (uniDistance) { url += '&minDist=' + minDistance }
        if (uniDistance) { url += '&maxDist=' + uniDistance }
        if (bedroomValueMax) { url += '&minRooms=' + bedroomValueMax }
        if (bedroomValueMax) { url += '&maxRooms=' + bedroomValueMax }
        if (rentValueMin) { url += '&minPrice=' + rentValueMin }
        if (rentValueMax) { url += '&maxPrice=' + rentValueMax }
        if (orgID) { url += '&organisationId=' + orgID }
        url += '&limit=10&skip=1'

        WebServiceClient.GetAll(url).then(function (response) {
                console.log(response)
                if (response.data && response.data.data) {
                    props.setProperties(response.data.data)
                    console.log('apidata in filter', response.data.data)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    useEffect(() => {
        handleFilter()
    }, [uniDistance, rentValueMin, bedroomValueMax, rentValueMax, orgID])

    //onChange each filter
    const onUniDistanceChange = (event) => {

        const value = event.target.value
        const newMinDistance = value === 5 ? 3: 0

        setMinDistance(newMinDistance)
        setUniDistance(value)
    }

    const getRentalYear = (event) => {
        setRentalYear(event.target.value)
    }

    const getOrgId = (e, option) => {
        setOrgID(option.id)
    }

    // fetch orglist
    useEffect(() => {
        const fetchOrgList = () => {
            WebServiceClient.FetchAll('swipad/orgs?orgId=118&siteId=null&limit=1&skip=0')
                .then(function (response) {
                    if (response.data && response.data.data) {
                        setOrgList(response.data.data)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        fetchOrgList()
    }, [])
    const handleChangeRentValueMin = (event) => {
        setRentValueMin(event.target.value);
    }
    const handleChangeRentValueMax = (event) => {
        setRentValueMax(event.target.value);
    }
    const handleChangeBedroomValueMax = (event) => {
        setBedroomValueMax(event.target.value)
        setBedroomValueLabel(event.target.value)
    }

    return (
        <Grid container direction="row" spacing={2} sx={style.filterStack}>
            <Grid xs={12} sm={12} md={3} lg={3}>
                <FormControl sx={style.filterControl}>
                    <FormLabel sx={style.filterLabel}>Distance to Uni</FormLabel>
                    <Select
                        sx={style.filterSelect}
                        labelId="distance-select-small"
                        id="distance-select-small"
                        value={uniDistance}
                        onChange={onUniDistanceChange}>
                        {filterData.distances.map((distance) => (
                            <MenuItem key={distance.value}
                                value={distance.value}
                            >
                                {distance.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            <Grid xs={12} sm={12} md={3} lg={3}>
                <FormControl sx={style.filterControl}>
                    <FormLabel sx={style.filterLabel}>Price PPP £</FormLabel>
                    <Box display="flex" marginTop="0px">
                        <TextField label="min" type="number" value={rentValueMin} onChange={handleChangeRentValueMin} sx={style.filterPrice} />
                        <TextField label="max" type="number" value={rentValueMax} onChange={handleChangeRentValueMax} sx={style.filterPrice} />
                    </Box>
                </FormControl>
            </Grid>

            <Grid xs={12} sm={12} md={3} lg={3}>
                <FormControl sx={style.filterControl}>
                    <FormLabel sx={style.filterLabel}># Bedrooms</FormLabel>
                    <Select
                        sx={style.filterSelect}
                        LabelId="bedroom-select-small"s
                        id="bedroom-select-small"
                        value={bedroomValueLabel}
                        onChange={handleChangeBedroomValueMax}>
                        {filterData.bedroomNumber.map((bedroom) => (
                            <MenuItem key={bedroom.value}
                                value={bedroom.value}
                            >
                                {bedroom.label}
                            </MenuItem>
                        ))
                        }
                    </Select>
                </FormControl>
            </Grid>

            {/* <Grid xs={12} sm={12} md={2} lg={2}>
                <FormControl sx={style.filterControl}>
                    <FormLabel sx={style.filterLabel}>When</FormLabel>
                    <Select
                        sx={style.filterSelect}
                        LabelId="when-select-small"
                        id="when-select-small"
                        value={rentalYear}
                        label="When"
                        input={<OutlinedInput label="When" />}
                        onChange={getRentalYear}>
                        {filterData.year.map((year) => (
                            
                            <MenuItem key={year.value}
                                value={year.value}
                            >
                                {year.label}
                            </MenuItem>
                        ))
                        }
                    </Select>
                </FormControl>
            </Grid> */}

            <Grid xs={12} sm={12} md={3} lg={3}>
                <FormControl sx={style.filterControl}>
                    <FormLabel sx={style.filterLabel}>Agent</FormLabel>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={orgList}
                        sx={{ width: "100%", margin: "20px 0", minWidth: '200px' }}
                        renderInput={(orgList) => <TextField {...orgList} label="Agent" />}
                        getOptionLabel={(option) => option.name}
                        onChange={getOrgId}
                    />
                </FormControl>
            </Grid>
        </Grid>
    );
}
