import React from 'react';
import Carousel from 'react-material-ui-carousel'

// import Card from '@mui/material/Card';

import CardMedia from '@mui/material/CardMedia';

export default function PropertySwipeImages(props) {
    const propertyPhotos = props.Images.listingMedia;

    return (
        <>

            <Carousel className="carousel">
                {
                    propertyPhotos.map((item, i) => <CardMedia
                    sx={{
                        width: "100%",
                        borderRadius: "10px"
                      }}
                    component="img"
                    key={item?.record?.id} 
                    image={item?.record?.fileURL} alt="property"/> )
                }
     
                
            </Carousel>
        </>

    )

};

